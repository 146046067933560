import { useEffect, useContext } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

export default function BlockerPage({ message, blockNavigate }) {
  let { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    if (!blockNavigate) return;

    const unblock = navigator.block((tx) => {
      if (window.confirm(message)) {
        unblock();
        tx.retry();
      }
    });

    return unblock;
  }, [navigator, message, blockNavigate]);

  return null;
}

export function MovePageSafe({ isBlockNavigate, handleAction }) {
  let { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    if (!isBlockNavigate) return;

    const unblock = navigator.block((tx) => {
      if (isBlockNavigate) {
        handleAction();
        unblock();
        tx.retry();
      }
    });

    return unblock;
  }, [navigator, isBlockNavigate]);

  return null;
}
