import { env } from "../env";

import mixpanel from "mixpanel-browser";
mixpanel?.init(env.REACT_APP_MIXPANEL);

let env_check = true;

let actions = {
  identify: (id) => {
    try {
      if (env_check) mixpanel?.identify(id);
    } catch (error) {
      console.log(error);
    }
  },
  alias: (id) => {
    if (env_check) mixpanel?.alias(id);
  },
  reset: () => {
    if (env_check) mixpanel?.reset();
  },
  track: (name, props) => {
    try {
      if (env_check) mixpanel?.track(name, props);
    } catch (error) {
      console.log(error);
    }
  },
  people: {
    set: (props) => {
      try {
        if (env_check) mixpanel?.people.set(props);
      } catch (error) {
        console.log(error);
      }
    },
  },
};

export let Mixpanel = actions;
