import React from "react";
import { Container, Form, InputGroup, Row } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
const CustomSearch = ({
  dataTableInfo,
  setFilteredDataTable,
  placeholder = "Buscar",
}) => {
  const isElementMatchWord = ({ element, word }) => {
    if (typeof element !== "string") return false;
    return element.toLowerCase().includes(word.toLowerCase());
  };
  const getKeys = ({ obj, word }) => {
    return Object.keys(obj).some((key) => {
      if (isElementMatchWord({ element: obj[key], word })) return true;
      const elements = obj[key]?.props?.children;
      if (!Array.isArray(elements)) return false;
      return elements?.some((element) =>
        isElementMatchWord({ element: element?.props?.children, word })
      );
    });
  };
  const filter = ({ dataTableInfo, filterInfo, setFilteredDataTable }) => {
    let filtered = [];
    if (filterInfo === "") {
      filtered = dataTableInfo.rows;
    } else {
      filtered = dataTableInfo.rows.filter((info) => {
        if (getKeys({ obj: info, word: filterInfo })) {
          return info;
        }
        return false;
      });
    }
    setFilteredDataTable({
      columns: dataTableInfo.columns,
      rows: filtered,
    });
  };
  return (
    <Container fluid>
      <Row>
        <InputGroup bsPrefix="input-group-container">
          <Form.Control
            bsPrefix="custom-input"
            placeholder={placeholder}
            onChange={(e) =>
              filter({
                dataTableInfo,
                filterInfo: e.target.value,
                setFilteredDataTable,
              })
            }
          />
          <InputGroup.Text bsPrefix="container-icon">
            <SearchIcon fontSize="large" />
          </InputGroup.Text>
        </InputGroup>
      </Row>
    </Container>
  );
};

export default CustomSearch;
