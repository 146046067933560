export const NUMBERINGWORDSO = [
  "",
  "primero",
  "segundo",
  "tercero",
  "cuarto",
  "quinto",
  "sexto",
  "séptimo",
  "octavo",
  "noveno",
  "décimo",
  "decimoprimero",
  "decimosegundo",
  "decimotercero",
  "decimocuarto",
  "decimoquinto",
  "decimosexto",
  "decimoséptimo",
  "decimoctavo",
  "decimonoveno",
  "vigésimo",
  "vigésimaprimero",
  "vigésimasegundo",
  "vigésimatercero",
  "vigésimacuarto",
  "vigésimoquinto",
  "vigésimosexto",
  "vigésimoséptimo",
  "vigésimooctavo",
  "vigésimonoveno",
  "trigésimo",
];

export const NUMBERINGWORDSA = [
  "",
  "Primera",
  "Segunda",
  "Tercera",
  "Cuarta",
  "Quinta",
  "Sexta",
  "Séptima",
  "Octava",
  "Novena",
  "Décima",
  "Decimoprimera",
  "Decimosegunda",
  "Decimotercera",
  "Decimocuarta",
  "Decimoquinta",
  "Decimosexta",
  "Decimoséptima",
  "Decimoctava",
  "Decimonovena",
  "Vigésima",
  "Vigésimaprimera",
  "Vigésimasegunda",
  "Vigésimatercera",
  "Vigésimacuarta",
  "Vigésimoquinta",
  "Vigésimosexta",
  "Vigésimoséptima",
  "Vigésimooctava",
  "Vigésimonovena",
  "Trigésima",
];
