import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";

const getExtesion = (file) => {
  let extension = file.name.split(".").pop().toLowerCase();
  return extension;
};

export function ShowDataExternalRequest({ userId, optionsToFilter }) {
  const subtypes = optionsToFilter.find(
    (option) => option.title === "Documento requerido"
  )
    ? optionsToFilter
        .find((option) => option.title === "Documento requerido")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;
  const requestStates = optionsToFilter.find(
    (option) => option.title === "Estado de la solicitud"
  )
    ? optionsToFilter
        .find((option) => option.title === "Estado de la solicitud")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;
  const laywersIds = optionsToFilter.find(
    (option) => option.title === "Usuario de servicios"
  )
    ? optionsToFilter
        .find((option) => option.title === "Usuario de servicios")
        .filters.filter((filter) => filter.checked === true)
        .map((option) => option.value)
    : null;

  return axios({
    method: "GET",
    url: `/thirdPartyRequest/external-user/requests/${userId}`,
    baseURL: env.REACT_APP_API_URL,
    params: {
      states: requestStates,
      subtypes: subtypes,
      attorneysIds: laywersIds,
    },
    headers: { Authorization: GetToken() },
  });
}

export function GetRequiredDocs(data) {
  return axios({
    method: "GET",
    url: "/thirdPartyRequest/requestSubtype/requiredDocs",
    baseURL: env.REACT_APP_API_URL,
    params: {
      requestSubType: data.subContractType,
      requestType: data.requestType,
    },
    headers: { Authorization: GetToken() },
  });
}

export function CreateComment(data) {
  return axios({
    method: "PUT",
    url: "/thirdPartyRequest/createComment",
    baseURL: env.REACT_APP_API_URL,
    data: {
      requestId: data.requestId,
      commentMessage: data.commentMessage,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function ChangeState(data) {
  return axios({
    method: "PUT",
    url: "/thirdPartyRequest/changeRequestState",
    baseURL: env.REACT_APP_API_URL,
    headers: {
      Authorization: GetToken(),
    },
    validateStatus: null,
    data: {
      requestId: data.requestId,
      state: data.state,
      isActive: data.isActive ? data.isActive : null,
    },
  });
}

export function CreateNewServicePassword(data) {
  return axios({
    method: "put",
    url: "users/newPasswordRequired",
    baseURL: env.REACT_APP_API_URL,
    data: data,
    validateStatus: null,
  });
}

export function GetRequestStatusInfo() {
  return axios({
    method: "GET",
    url: "states/get/third-party-request-states",
    baseURL: env.REACT_APP_API_URL,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function GetAttorneys(data) {
  return axios({
    method: "GET",
    url: `/thirdPartyRequest/getattorneys/${data.companyId}`,
    params: {
      isApplied: true,
      corporateUnit: data?.corporateUnit || "",
    },
    baseURL: env.REACT_APP_API_URL,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export function SendInfoToSupport(data) {
  const formData = new FormData();
  formData.append("subject", data.subject);
  formData.append("message", data.message);
  formData.append("userEmail", data.userEmail);
  formData.append("firstName", data.firstName);
  if (data.lastName) {
    formData.append("lastName", data.lastName);
  }
  formData.append("token", data.token);
  formData.append(
    "additionalInfo",
    data.additionalInfo ? JSON.stringify(data.additionalInfo) : "{}"
  );
  if (data.supportDocuments?.length) {
    data.supportDocuments.map((file) => {
      formData.append("supportDocuments", file);
    });
  }
  return axios({
    method: "POST",
    url: `users/send-email/technical-support/`,
    data: formData,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      Authorization: GetToken(),
    },
  });
}

export const getFileDocumentForExternal = (
  filenameInBucket,
  bucketName,
  isWord
) => {
  return axios({
    method: "GET",
    url: `versions/getfile`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
    params: { filenameInBucket, bucketName, isWord },
  });
};
