import { handleStyleStatusRequest } from "../../../../utils/statusExtenalRequest";
import { validateCompleteSigns } from "../../../Standard/ViewRequestStandard";

export const getTextFollowup = (filterDocument) => {
    if (filterDocument.isSignatureFinish) {
        return (
            <label className="caption">
                {filterDocument.tracking}
            </label>
        );
    } else if (
        !filterDocument.isSignatureFinish &&
        filterDocument.userRole === "EXTERNAL"
    ) {
        return (
            <label className="caption">
                {"#" + filterDocument.consecutive + " Respuesta Solicitante"}
            </label>
        );
    } else if (
        !filterDocument.isSignatureFinish &&
        filterDocument.userRole === "SERVICE"
    ) {
        return (
            <label className="caption">
                {"#" + filterDocument.consecutive + " Respuesta Abogado"}
            </label>
        );
    } else return <label>---</label>;
};

export const filterDataRequest = (data) => ({
    _id: data._id,
    stateRequest: data.requestStatus.name,
    color: handleStyleStatusRequest(data?.requestStatus?.colorId),
    applicant:
      data.applicant.firstName + " " + data.applicant.firstSurname,
    applicantId: data.applicant._id,
    attorneyInCharge:
      data.attorneyInCharge.firstName +
      " " +
      data.attorneyInCharge.firstSurname,
    requestSubject: data.requestSubject,
    requestType: data.requestType,
    requestSubType: data.requestSubType?.name,
    responseLimitDate: data.responseLimitDate,
    requestDetails: data.requestDetails,
    documentsOtherAttorney: data.documents.attorneyDocuments,
    documentsAttachments: data.documents.attorneyDocuments.concat(
      data.documents.applicantDocuments,
      data.documents.requiredDocuments
    ),
    documentsOtherApplicant: data.documents.applicantDocuments,
    documentPrincipal: data?.documents?.mainDocuments.filter(doc => !(!doc.isRejected && !validateCompleteSigns(data.signatures, doc) && doc.subtype === "Signature")),
    comments: data.comments,
  })