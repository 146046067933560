import Axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";
export function getOneUnitCorporativeById(id) {
  return Axios({
    method: "GET",
    url: `corporate-unit/findOneCorporateUnit/${id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
