import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";
import { CODES } from "../../utils/codes";
export const GetPlanInfo = async () => {
  const companyId = localStorage.getItem("company");
  const companyPlan = await findCompanyPlan({ companyId });
  if (
    companyPlan.status === CODES.COD_RESPONSE_HTTP_OK &&
    companyPlan.data.success
  ) {
    return companyPlan?.data?.responseMessage?.data?.plan;
  }
  return null;
};

export const validateEnablePlan = async () => {
  const companyPlanInfo = await GetPlanInfo();

  const limitExtensions = companyPlanInfo?.limitsExtension;

  const limitUserExtensions = companyPlanInfo?.limitsExtension?.users;

  const storageUsed = companyPlanInfo?.storageUsed;

  const capacityStorage =
    companyPlanInfo?.businessPlan?.storage + limitExtensions?.storage;

  const capacityUser = companyPlanInfo?.businessPlan?.users;

  const usersCreated = companyPlanInfo?.actualUsers;

  const capacitySignature =
    companyPlanInfo?.businessPlan?.signatures + (limitExtensions?.signatures || 0);

  const signaturesCreated = companyPlanInfo?.signaturesUsed;

  const capacityRequest =
    companyPlanInfo?.businessPlan?.request + limitExtensions?.requests;

  const requestsCreated = companyPlanInfo?.requestsUsed;

  const capacityContract =
    companyPlanInfo?.businessPlan?.contracts + limitExtensions?.contracts;

  const contractsCreated = companyPlanInfo?.contractsUsed;

  const infoCompanyPlan = {
    isStorageEnable: storageUsed < capacityStorage,
    isUserEnable: usersCreated < capacityUser,
    isSignatureEnable: signaturesCreated < capacitySignature,
    isRequestsEnable: requestsCreated < capacityRequest,
    isContractsEnable: contractsCreated < capacityContract,
  };

  return infoCompanyPlan;
};
export function findCompanyPlan(data) {
  return axios({
    method: "GET",
    url: `plans/findCompanyPlan`,
    params: { companyId: data.companyId },
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}
