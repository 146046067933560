import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Container
      fluid
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "250px",
      }}
    >
      <Row>
        <Container
          style={{
            borderRadius: "10px",
            boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.07)",
            padding: "30px",
          }}
        >
          <Row
            className="home-init-cards-row__title-row"
            style={{ fontSize: "30px" }}
          >
            Ups, parece que te has perdido
          </Row>
          <Row>
            <Col>
              <img
                src={require("../assets/svg/NotFoundCharacter.png")}
                alt=""
                style={{
                  width: "300px",
                  height: "300px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </Col>
            <button
              className="btn_blue_background"
              style={{
                padding: "10px",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
              onClick={() => navigate("/external/home")}
            >
              Volver
            </button>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default NotFound;
