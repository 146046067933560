import axios from "axios";
import GetToken from "../../utils/getToken";
import { env } from "../../env";
export function findAllHeadingsExternal(data) {
  return axios({
    method: "GET",
    url: `letterheads/findAll`,
    baseURL: env.REACT_APP_API_URL,
    params: data,
    validateStatus: null,
    headers: {
      Authorization: GetToken(),
    },
  });
}

export const previewHeadingDocument = ({
  documentPdfTemplate,
  documentDocxTemplate,
  file,
  originalname,
  contentType,
}) => {
  const formData = new FormData();
  formData.append("file", file);
  if (documentDocxTemplate) {
    formData.append("documentDocxTemplate", documentDocxTemplate);
  }

  formData.append("documentPdfTemplate", documentPdfTemplate);
  formData.append("originalname", originalname);
  formData.append("contentType", contentType);
  return axios({
    method: "POST",
    url: `letterheads/previewApplyToDocument`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,

    headers: {
      Authorization: GetToken(),
    },
    data: formData,
  });
};
