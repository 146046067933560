import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrum from "../../pages/parts/breadcrum";
import AddIcon from "@mui/icons-material/Add";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CardButtonWithNavButton from "../../components/IconCard/CardButtonWithNavButton";
import SectionTitle from "../../components/sectionTitle";
import { useDispatch } from "react-redux";
import {
  setSelectedHeader,
  setSelectedHeaderId,
} from "../../reducers/headingsSlice";
import validatePermissions from "../../services/permissions/permissionsServices";
import LoadingContent from "../../components/LoadingContent";

const CreateService = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [canCreateContract, setCanCreateContract] = useState(false);

  const permissionsService = async () => {
    try {
      setIsLoading(true);
      const { isAllowCreateContract } = await validatePermissions();
      setCanCreateContract(isAllowCreateContract);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    permissionsService();
    dispatch(setSelectedHeader(null));
    dispatch(setSelectedHeaderId(null));
  }, []);

  return (
    <Container fluid style={{ height: "100%" }}>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum title="" />
      </Row>
      <Row style={{ alignContent: "center" }}>
        <Col xs={"auto"}>
          <SectionTitle title="Crear" />
        </Col>
      </Row>
      <Row style={{ height: "6%", margin: "2% 0" }} className="label">
        ¿Qué es lo que quieres crear hoy?
      </Row>
      <Row style={{ height: "40%", rowGap: "15px" }}>
        <Col className="col-flex">
          <CardButtonWithNavButton
            icon={<EmailOutlinedIcon className="cards__icon-svg" />}
            title="Crear una solicitud"
            description="Solicita la revisión o la creación de documentos a nuestros expertos legales."
            navigationButton={<AddIcon className="cards__nav-button__icon" />}
            extendedPath="request"
            mixPanelTrack="Solicitante Crear Solicitud"
          />
        </Col>
        <Col className="col-flex">
          <CardButtonWithNavButton
            icon={<DescriptionOutlinedIcon className="cards__icon-svg" />}
            title="Crear un contrato"
            description="Crea un contrato tu mismo de forma rápida y fácil desde una minuta."
            navigationButton={<AddIcon className="cards__nav-button__icon" />}
            extendedPath="contract"
            disabled={!canCreateContract}
            mixPanelTrack="Solicitante Crear Contrato"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CreateService;
