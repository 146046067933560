import { Col, Container, Row } from "react-bootstrap";
import SidebarApprover from "../../components/Sidebar/SidebarApprover";
import { Outlet } from "react-router-dom";

const ApproverLayout = () => {
  return (
    <Container
      fluid
      style={{ width: "100vw", height: "100vh", overflow: "hidden" }}
    >
      <Row style={{ height: "inherit" }}>
        <Col md={2} className="sidebar-base">
          {/**Padding crítico pero necesario */}
          <SidebarApprover />
        </Col>
        <Col>
          {/* // <-- nested routes rendered here --> */}
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default ApproverLayout;
