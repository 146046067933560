import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { Logout } from "../../services/Login/Login";
import { Mixpanel } from "../../utils/mixPanel";

const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;

  return <DialogTitle {...other}>{children}</DialogTitle>;
};

const ModalInactivity = ({ isOpen, setIsOpen }) => {
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setIsOpen(false);
    }
  };

  const handleLogoutAgree = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
      await Logout({ refreshToken, email: payloadToken.email });
      localStorage.clear();
      window.location.replace("/");
      Mixpanel.reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        disableEscapeKeyDown={true}
        onClose={handleClose}
        open={isOpen}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__warning-color">
            <InfoOutlinedIcon fontSize="large" />
            Cierre de sesión
          </label>
        </DialogTitleContainer>
        <DialogContent
          className="caption "
          dividers
          style={{ paddingTop: "15px", paddingBottom: "30px" }}
        >
          Tu sesión ha estado inactiva por mucho tiempo, por seguridad debes
          cerrar sesión
        </DialogContent>
        <DialogActions className="p-4">
          <div>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={handleLogoutAgree}
            >
              Cerrar Sesion
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalInactivity;
