import { permissionsUsersByEmail } from "../utils";

const validatePermissions = async () => {
    try {
        const { email = "" } = JSON.parse(localStorage.getItem("payloadToken"))
        const responsePermissions = await permissionsUsersByEmail(email);
        const permissions = responsePermissions?.data?.responseMessage?.permissions || [];
        const validatePermissionsDownload = permissions?.find(
            ({ module }) => module === "CreateContracts"
        )?.actions?.download;
        const validatePermissionsRequests = permissions?.find(
            ({ module }) => module === "Requests"
        )?.actions?.readAsignedRequests;
        const validatePermissionsCreateContracts = permissions?.find(
            ({ module }) => module === "CreateContracts"
        )?.actions?.createByContractType;
        return {
            isAllowDownload: validatePermissionsDownload,
            viewRequest: validatePermissionsRequests,
            isAllowCreateContract: validatePermissionsCreateContracts,
        }
    } catch (error) {
        console.error(error);
    }
};

export default validatePermissions;