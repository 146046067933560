import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import {
  Row,
  Form,
  Col,
  Spinner,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import { CODES } from "../../utils/codes";
import ModalInfo from "./ModalInfo";
import { SendInfoToSupport } from "../../services/ExternalUsers/ExternalRequest";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import Modal from "react-bootstrap/Modal";
import {
  DownLoadRecentDocument,
  responseDataFileSize,
  responseDataInvalidFormat,
  responseDataNoFile,
  validationsSupportDocuments,
} from "../../utils/downloadDocuments";
import { REGEXP } from "../../utils/regexp";
import recaptcha from "../../assets/svg/recaptcha.png";
import recaptchaBadge from "../../assets/svg/recaptchaBadge.png";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
const ModalSupport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [isOpenModalNoFile, setIsOpenModalNoFile] = useState(false);
  const [isOpenModalInvalidFormat, setIsOpenModalInvalidFormat] =
    useState(false);
  const [documentIndex, setDocumentIndex] = useState(0);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    reset();
    setOpen(false);
  };
  const handleReset = () => {
    reset();
  };

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" })
      .max(60, "Máximo 60 caracteres permitidos"),
    surnames: yup
      .string()
      .required("*Este campo es obligatorio")
      .matches(REGEXP.ONLY_LETTERS, { message: "*Solo debe incluir letras" })
      .max(60, "Máximo 60 caracteres permitidos"),
    email: yup
      .string()
      .email("*Este campo debe ser un email válido")
      .required("*Este campo es requerido"),
    subject: yup
      .string()
      .required("*Este campo es obligatorio")
      .max(120, "Máximo 120 caracteres permitidos"),
    description: yup
      .string()
      .required("*Este campo es obligatorio")
      .max(1000, "Máximo 1000 caracteres permitidos"),
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    name: "supportDocuments",
    control,
  });

  const handleFileSize = () => {
    setIsOpenModalInfo(false);
  };

  const handleInfoToAdminSupport = async (data) => {
    try {
      setIsLoading(true);

      const validateDocuments = validationsSupportDocuments(
        data.supportDocuments
      );

      if (validateDocuments?.isNoFile) {
        setIsOpenModalNoFile(true);
        throw new Error("exit");
      }
      if (validateDocuments?.isInvalidFormat) {
        setIsOpenModalInvalidFormat(true);
        throw new Error("exit");
      }
      if (validateDocuments?.isInvalidSize) {
        setIsOpenModalInfo(true);
        throw new Error("exit");
      }

      const captchaToken = await executeRecaptcha("Support");

      const obj = {
        subject: data.subject,
        message: data.description,
        userEmail: data.email,
        firstName: data.firstName,
        lastName: data.surnames,
        supportDocuments: data?.supportDocuments?.map((document) => {
          return document[0];
        }),
        token: captchaToken,
      };

      const service = await SendInfoToSupport(obj);
      if (service) {
        if (service.status === CODES.COD_RESPONSE_HTTP_CREATED) {
          setResponseData(service);
          setIsOpenModal(true);
          handleClose();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteOtherDocument = (index) => {
    remove(index);
  };

  const handleDownloadRecentDocument = (index) => {
    const getFile = getValues(`supportDocuments.${index}`);
    DownLoadRecentDocument(getFile);
  };

  const handleUploadOtherDocument = (index) => {
    setDocumentIndex(index);
    append({});
  };

  useEffect(() => {
    const inputElement = document.getElementById(
      `supportDocuments.${documentIndex}`
    );

    if (inputElement) {
      inputElement.dispatchEvent(new MouseEvent("click"));
    }
  }, [fields]);
  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      await executeRecaptcha("Support");
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  return (
    <div>
      <Button className="custom-input__button" onClick={handleClickOpen}>
        <u className="custom-input__button__link">Tengo un problema</u>
      </Button>
      <Modal onHide={handleClose} show={open} centered>
        <Form onSubmit={handleSubmit(handleInfoToAdminSupport)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <label className="heading__primary-color">Soporte Legal AI</label>
            </Modal.Title>
          </Modal.Header>

          <ModalBody scrollable>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="caption">Nombre:</Form.Label>
                  <Form.Control
                    {...register("firstName")}
                    type="text"
                    placeholder="Ingresa tu nombre"
                    size="lg"
                    rows={3}
                    maxLength="61"
                  />
                  <p
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.firstName?.message}
                  </p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="caption">Apellidos:</Form.Label>
                  <Form.Control
                    {...register("surnames")}
                    type="text"
                    placeholder="Ingresa tus apellidos"
                    size="lg"
                    rows={3}
                    maxLength="61"
                  />
                  <p
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.surnames?.message}
                  </p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="caption">
                    Correo electrónico:
                  </Form.Label>
                  <Form.Control
                    {...register("email")}
                    type="text"
                    placeholder="Usuario@ejemplo.com"
                    size="lg"
                    rows={3}
                  />
                  <p
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.email?.message}
                  </p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="caption">Asunto: </Form.Label>
                  <Form.Control
                    {...register("subject")}
                    type="text"
                    placeholder="Solicitud de..."
                    size="lg"
                    rows={3}
                    maxLength="121"
                  />
                  <p
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.subject?.message}
                  </p>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="caption">Descripción: </Form.Label>
                  <Form.Control
                    {...register("description")}
                    type="text"
                    as="textarea"
                    placeholder="Necesito ayuda en..."
                    size="lg"
                    rows={5}
                    maxLength="1000"
                  />
                  <p
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.description?.message}
                  </p>
                </Form.Group>
              </Col>
            </Row>
            <>
              {fields.map((field, index) => {
                const file =
                  watch(`supportDocuments.${index}`) &&
                  watch(`supportDocuments.${index}`)[0];
                return (
                  <Row key={field.id}>
                    <Col>
                      <Form.Control
                        key={field.id}
                        id={`supportDocuments.${index}`}
                        className="input-upload"
                        {...register(`supportDocuments.${index}`)}
                        type="file"
                      />
                      <Row
                        htmlFor={`supportDocuments.${index}`}
                        style={{
                          color: "#00374F",
                          height: "max-content",
                          margin: "1rem 0rem",
                          boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                          borderRadius: "8px",
                        }}
                      >
                        <Col style={{ margin: "1.5rem" }} md={7}>
                          <Row className="label__title">{`Documento ${
                            index + 1
                          }`}</Row>
                          <Row>{file ? `${file.name}` : ""}</Row>
                        </Col>
                        <Col style={{ margin: "1.5rem" }} md={3}>
                          <Row>
                            <Col md={6}>
                              <Form.Label
                                onClick={() =>
                                  handleDownloadRecentDocument(index)
                                }
                                className="btn_blue_background"
                                style={{
                                  backgroundColor: "#00374F",
                                  marginRight: "1rem",
                                  padding: "7px",
                                  cursor: "pointer",
                                }}
                              >
                                <DownloadIcon fontSize="large" />
                              </Form.Label>
                            </Col>
                            <Col md={6}>
                              <Form.Label
                                className="btn_blue_background"
                                color="#676879"
                                style={{
                                  backgroundColor: "#00374F",
                                  marginRight: "1rem",
                                  padding: "7px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleDeleteOtherDocument(index)}
                              >
                                <DeleteIcon fontSize="large" />
                              </Form.Label>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
              <br />
              {fields.length < 3 && (
                <Row xs={"auto"}>
                  <Button
                    type="button"
                    variant="contained"
                    startIcon={<AddIcon fontSize="large" />}
                    className="custom-input__button__secondary-color"
                    onClick={() => handleUploadOtherDocument(fields.length)}
                  >
                    Cargar Documentos
                  </Button>
                </Row>
              )}
              <Row className="justify-content-center align-items-center pt-2 mt-4">
                <Col xs={2} className="text-end">
                  <a href="https://policies.google.com/privacy">
                    <img src={recaptchaBadge} alt="recaptcha" />
                  </a>
                </Col>

                <Col xs={8} className="text-start">
                  <a href="https://policies.google.com/terms">
                    <img src={recaptcha} alt="recaptcha badge" />
                  </a>
                </Col>
              </Row>
            </>
          </ModalBody>
          <ModalFooter className="justify-content-center align-items-center pt-2">
            <Col xs={4} className="text-center">
              <button
                type="button"
                className="btn_white_background"
                style={{ padding: "12px 24px", marginRight: "2 rem" }}
                onClick={handleReset}
              >
                Restablecer
              </button>
            </Col>
            <Col xs={4} className="text-center">
              <button
                type="submit"
                className="btn_blue_background"
                style={{ padding: "12px 24px" }}
              >
                {isLoading ? (
                  <Spinner
                    animation="border"
                    className="spinner__blue_background"
                  />
                ) : (
                  "Enviar"
                )}
              </button>
            </Col>
          </ModalFooter>
        </Form>
      </Modal>
      <ModalInfo
        title={"Soporte Legal AI"}
        responseData={responseData}
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
      <ModalInfo
        title="Cajas de archivos vacías"
        open={isOpenModalNoFile}
        onClose={() => setIsOpenModalNoFile(false)}
        responseData={responseDataNoFile}
      />
      <ModalInfo
        title="Formato de archivos inválido"
        open={isOpenModalInvalidFormat}
        onClose={() => setIsOpenModalInvalidFormat(false)}
        responseData={responseDataInvalidFormat}
      />
      <ModalInfo
        title="Peso de archivos inválido"
        open={isOpenModalInfo}
        onClose={handleFileSize}
        responseData={responseDataFileSize}
      />
    </div>
  );
};

export default ModalSupport;
