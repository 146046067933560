import React from "react";
import Tooltip from "@mui/material/Tooltip";

export const CustomToolTip = ({ direction, message, children }) => {
  return (
    <Tooltip
      title={message}
      placement={direction}
      arrow
      classes={{ tooltip: "custom-tooltip" }}
    >
      <span>{children}</span>
    </Tooltip>
  );
};
