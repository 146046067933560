const SIGNATORY_DEFAULT_NATURAL = {
  type: "natural",
  blankspaces: [
    { index: "0", value: "", label: "Nombre", name: "blankSpace-1" },
    {
      index: "1",
      value: "",
      label: "Tipo de documento",
      name: "blankSpace-2",
    },
    {
      index: "2",
      value: "",
      label: "Número de documento",
      name: "blankSpace-3",
    },
    { index: "3", value: "", label: "Domicilio", name: "blankSpace-4" },
  ],
};

const SIGNATORY_DEFAULT_LEGAL = {
  type: "legal",
  blankspaces: [
    { index: "0", value: "", label: "Nombre sociedad", name: "blankSpace-1" },
    {
      index: "1",
      value: "",
      label: "Tipo de documento de sociedad",
      name: "blankSpace-2",
    },
    {
      index: "2",
      value: "",
      label: "Número de documento de la sociedad",
      name: "blankSpace-3",
    },
    {
      index: "3",
      value: "",
      label: "Domicilio de la sociedad",
      name: "blankSpace-4",
    },
    {
      index: "4",
      value: "",
      label: "Nombre del representante legal",
      name: "blankSpace-5",
    },
    {
      index: "5",
      value: "",
      label: "Tipo de documento del representante legal",
      name: "blankSpace-6",
    },
    {
      index: "6",
      value: "",
      label: "Número de documento de representante legal",
      name: "blankSpace-7",
    },
  ],
};

const OTHER_SIGNATORY_DEFAULT_NATURAL = {
  type: "natural",
  blankspaces: [
    { index: "0", value: "", label: "Cargo", name: "blankSpace-1" },
    { index: "1", value: "", label: "Nombre", name: "blankSpace-2" },
    {
      index: "2",
      value: "",
      label: "Tipo de documento",
      name: "blankSpace-3",
    },
    {
      index: "3",
      value: "",
      label: "Número de documento",
      name: "blankSpace-4",
    },
  ],
};

export const signatoriesTypes = {
  SignatariOPN: {
    text: "<p><span data-blank-space='blankSpace-1' class='blank-space-text'>¬ESPACIO #1¬</span>, quien se identifica con <span data-blank-space='blankSpace-2' class='blank-space-text'>¬ESPACIO #2¬</span> <span data-blank-space='blankSpace-3' class='blank-space-text'>¬ESPACIO #3¬ </span> y con domicilio en <span data-blank-space='blankSpace-4' class='blank-space-text'>¬ESPACIO #4¬</span>.</p>",
    gender: "M",
    name: "SignatariO (Persona natural)",
    ...SIGNATORY_DEFAULT_NATURAL,
  },

  SignatariAPN: {
    text: "<p><span data-blank-space='blankSpace-1' class='blank-space-text'>¬ESPACIO #1¬</span>, quien se identifica con <span data-blank-space='blankSpace-2' class='blank-space-text'>¬ESPACIO #2¬</span> <span data-blank-space='blankSpace-3' class='blank-space-text'>¬ESPACIO #3¬ </span> y con domicilio en <span data-blank-space='blankSpace-4' class='blank-space-text'>¬ESPACIO #4¬</span>.</p>",
    gender: "F",
    name: "SignatariA (Persona natural)",
    ...SIGNATORY_DEFAULT_NATURAL,
  },

  SignatariOPJ: {
    text: '<p><span data-blank-space="blankSpace-1" class="blank-space-text">¬ESPACIO #1¬</span>, que se identifica con <span data-blank-space="blankSpace-2" class="blank-space-text">¬ESPACIO #2¬</span> <span data-blank-space="blankSpace-3" class="blank-space-text"> ¬ESPACIO #3¬</span>  con domicilio en  <span data-blank-space="blankSpace-4" class="blank-space-text">¬ESPACIO #4¬</span>  representada por  <span data-blank-space="blankSpace-5" class="blank-space-text">¬ESPACIO #5¬</span>  quien se identifica con <span data-blank-space="blankSpace-6" class="blank-space-text">¬ESPACIO #6¬</span> <span data-blank-space="blankSpace-7" class="blank-space-text">¬ESPACIO #7¬</span> y que actúa en calidad de representante legal .</p>',
    gender: "M",
    name: "SignatariO (Persona jurídica)",
    ...SIGNATORY_DEFAULT_LEGAL,
  },

  OtherSignatory: {
    text: "<p><span data-blank-space='blankSpace-1' class='blank-space-text'>¬ESPACIO #1¬</span></p><p><span data-blank-space='blankSpace-2' class='blank-space-text'>¬ESPACIO #2¬</span></p><p> <span data-blank-space='blankSpace-3' class='blank-space-text'>¬ESPACIO #3¬</span> <span data-blank-space='blankSpace-4' class='blank-space-text'>¬ESPACIO #4¬</span> </p>",
    gender: "M",
    name: "Otro Firmante",
    ...OTHER_SIGNATORY_DEFAULT_NATURAL,
  },

  NaturalSignatory: {
    text: "<p><span data-blank-space='blankSpace-1' class='blank-space-text'>¬ESPACIO #1¬</span></p><p><span data-blank-space='blankSpace-2' class='blank-space-text'>¬ESPACIO #2¬</span></p><p> <span data-blank-space='blankSpace-3' class='blank-space-text'>¬ESPACIO #3¬</span> <span data-blank-space='blankSpace-4' class='blank-space-text'>¬ESPACIO #4¬</span> </p>",
    gender: "M",
    name: "Otro Firmante",
    ...OTHER_SIGNATORY_DEFAULT_NATURAL,
  },
};

export const DEFAULT_SIGNATORY = {
  gender: "M",
  personType: "natural",
  blankspaces: signatoriesTypes.NaturalSignatory.blankspaces,
  text: signatoriesTypes.NaturalSignatory.text,
};
export const DEFAULT_PART = {
  gender: "M",
  personType: "natural",
  blankspaces: signatoriesTypes.SignatariOPN.blankspaces,
  text: signatoriesTypes.SignatariOPN.text,
  signatories: [DEFAULT_SIGNATORY],
};
