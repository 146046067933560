import React, { useState, useEffect } from "react";
import { Container, Form, InputGroup, Row, Col } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Button, Menu, MenuItem, ListItemText } from "@mui/material";
import FilterByCarvajal from "./FilterByCarvajal";
import { statusList } from "../../../utils/statusExtenalRequest";
import CardInformative from "../../../components/IconCard/Cardinformative";
import { useNavigate } from "react-router-dom";
import { getRequestSummary } from "../../../services/CarvServices/CarvajalServices";
import { CODES } from "../../../utils/codes";
import LoadingContent from "../../../components/LoadingContent";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ReadMoreOutlinedIcon from '@mui/icons-material/ReadMoreOutlined';

const isElementMatchWord = ({element, word}) => {
  if (!element) return false;
  return element?.toLocaleString().toLowerCase().includes(word.toLowerCase());
};

const SearchBarCarvajal = ({
  DataTable,
  setFilteredDataTable,
  setFilterStatus = null,
  setOptionsFilterBar,
}) => {
  const navigate = useNavigate();
  const [requestCards, setRequestCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, showFilters] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    showFilters(false);
  };
  const handleFilterStatus = (status) => {
    if (setFilterStatus) {
      setFilterStatus(status);
    }
  };

  function filter(filterInfo) {
    let filtered = [];
    if (filterInfo === "") {
      filtered = DataTable.rows;
    } else {
      filtered = DataTable.rows.filter((info) => {
        const values = Object.values(info);
        if (
          values.some(value => isElementMatchWord({element: value, word: filterInfo.toLowerCase()}))
        ) {
          return info;
        }
      });
    }
    setFilteredDataTable({
      columns: DataTable.columns,
      rows: filtered,
    });
  }

  const handleCreate = () => {
    navigate("/external/create");
  };

  const tokenInfo = JSON.parse(localStorage.payloadToken);
  const { userId } = tokenInfo;
  const companyId = localStorage.getItem("company");
  const {_id} = JSON.parse(localStorage.getItem("corporateUnit"));

  const fetchRequestData = async () => {
    setIsLoading(true);
    try {
      const obj = {
        userId: userId,
        companyId: companyId,
        corporateUnitId: _id
      };
      const service = await getRequestSummary(obj);
        if (service?.status === CODES.COD_RESPONSE_HTTP_OK) {
          const { data } = service.data.responseMessage;
          const newArray = Object.entries(data).map((entry) => {
            const [key, value] = entry;
            if (key === "openRequests") {
              return {
                number: value,
                description: "Abiertas",
                icon: <ReadMoreOutlinedIcon fontSize="large" color="action" />,
                color: "#ECF7F9",
                filterBy: "open",
              };
            }
            if (key === "reviewApplicantRequests") {
              return {
                number: value,
                description: "R. Solicitante",
                icon: <RemoveRedEyeOutlinedIcon fontSize="large" color="action"/>,
                color: "#FFD7B1",
                filterBy: "attorneyReview",
              };
            }
            if (key === "expiredRequests") {
              return {
                number: value,
                description: "Expiradas",
                icon: <ReadMoreOutlinedIcon fontSize="large" color="action" />,
                color: "#F9DADE",
                filterBy: "expired",
              };
            }
          });
          setRequestCards(newArray);
        }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchRequestData();
  }, []);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row style={{ display: "flex", alignItems: "center" }}>
        <Col md={2}>
          <InputGroup bsPrefix="input-group-container">
            <Form.Control
              bsPrefix="custom-input"
              placeholder="Buscar"
              onChange={(event) => filter(event.target.value)}
            />
            <InputGroup.Text bsPrefix="container-icon">
              <SearchIcon fontSize="large" />
            </InputGroup.Text>
          </InputGroup>
        </Col>
        <Col md={7} style={{ display: "flex", flexDirection: "row" }}>
          {requestCards?.length > 0 &&
            requestCards.map((card) => (
              <Container fluid key={card.description}>
                <CardInformative
                  number={card.number}
                  description={card.description}
                  color={card.color}
                  icon={card.icon}
                  size="XSmall"
                />
              </Container>
            ))}
        </Col>
        <Col xs={"auto"}>
          <Button
            variant="contained"
            startIcon={<FilterAltIcon fontSize="large" />}
            className="custom-input__button__secondary-color"
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            Filtrar
          </Button> 
        </Col>
        <Col xs={"auto"}>
          <Button
            type="button"
            className="custom-input__button__primary-color"
            variant="contained"
            onClick={handleCreate}
            startIcon={<AddOutlinedIcon fontSize="large" />}
          >
            Crear
          </Button>
        </Col>
      </Row>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={filters}
        onClose={handleClose}
      >
        {statusList.map((name) => (
          <MenuItem key={name.id} value={name.text}>
            <ListItemText
              primary={name.text}
              onClick={() => {
                handleFilterStatus(name.key);
              }}
            />
          </MenuItem>
        ))}
      </Menu>
      <FilterByCarvajal
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setOptionsFilterBar={setOptionsFilterBar}
      />
    </Container>
  );
};

export default SearchBarCarvajal;
