import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";

const schema = yup.object().shape({
  signatories: yup.array().of(
    yup.object().shape({
      role: yup
        .string()
        .required("**Este campo es requerido")
        .max(250, "Máximo 250 caracteres permitidos"),
    })
  ),
});

const DialogTitleContainer = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const ModalSelectRoleSignatory = ({
  isOpen,
  setIsOpen,
  selectedSignatories,
  setSelectedSignatories,
  submitForm,
  description,
  title,
}) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  const getName = (item) => {
    return `${item?.firstName || ""} ${item?.secondName || ""} ${
      item?.firstSurname || ""
    } ${item?.secondSurname || ""}`;
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      signatories: selectedSignatories,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "signatories",
  });

  const handleRoleChange = (index, value) => {
    const updatedItems = selectedSignatories.map((item, i) => {
      if (index !== i) {
        return item;
      }
      return { ...item, role: value };
    });
    setSelectedSignatories(updatedItems);
  };

  useEffect(() => {
    setValue("signatories", selectedSignatories);
  }, [selectedSignatories, setValue]);
  return (
    <div>
      <Dialog
        maxWidth="xs"
        disableEscapeKeyDown={true}
        onClose={handleClose}
        open={isOpen}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleContainer onClose={handleClose}>
          <label className="heading__primary-color">{title}</label>
        </DialogTitleContainer>

        <DialogContent
          dividers
          style={{ paddingTop: "15px", paddingBottom: "30px" }}
        >
          <Row>
            <Col xs={12}>
              <p className="caption">{description}</p>
            </Col>
            {fields?.map((item, index) => {
              return (
                <Row>
                  <Col xs={12} key={item?._id}>
                    <Form.Label className="form__label">
                      {`${getName(item)} `}
                    </Form.Label>
                    <Form.Control
                      {...register(`signatories.${index}.role`)}
                      onChange={(e) => handleRoleChange(index, e.target.value)}
                      placeholder={`Calidad`}
                      bsPrefix={
                        errors?.signatories?.[index]
                          ? "input-group-container__no-icon-error label"
                          : "input-group-container__no-icon label"
                      }
                    />
                    <div className="caption custom-input__error">
                      {errors?.signatories?.[index]?.role?.message}
                    </div>
                  </Col>
                </Row>
              );
            })}
          </Row>
        </DialogContent>
        <DialogActions className="p-4">
          <Row>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                className="custom-input__button__secondary-color"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={"auto"}>
              <Button
                variant="contained"
                className="custom-input__button__primary-color"
                onClick={handleSubmit(submitForm)}
              >
                Aceptar
              </Button>
            </Col>
          </Row>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalSelectRoleSignatory;
