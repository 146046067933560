export default function dateCon(createdAt) {
  const date = new Date(createdAt);

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const hour = date.getHours();
  let minute = date.getMinutes();

  if (minute < 10) {
    minute = "0" + minute;
  }
  const mes = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  return (
    day + " de " + mes[month] + " del " + year + " , " + hour + ":" + minute
  );
}

export function dateNormal(createdAt) {
  const date = new Date(createdAt);

  const day = date.getDate();
  let month = date.getMonth();
  const year = date.getFullYear();
  if (month === 9) {
    month = month + 1;
  } else if (month < 9) {
    month = "0" + (month + 1);
  }

  return day + "/" + month + "/" + year;
}
