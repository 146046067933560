import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { saveAs } from "file-saver";
import { Buffer } from "buffer";

import { useParams, useNavigate } from "react-router-dom";
import LoadingContent from "../../../../../components/LoadingContent";
import {
  addComment,
  getApprovalById,
  getFileDocument,
} from "../../../../../services/Approvals/ApprovalServices";
import { CODES } from "../../../../../utils/codes";
import { findOneDataExternalRequest } from "../../../../../services/CarvServices/CarvajalServices";

const AdjustmentService = () => {
  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use Params
   */

  const { consecutive, approvalId } = useParams();

  /**
   * Use State
   */
  const [adjustmentMessage, setAdjustmentMessage] = useState("");

  const [currentApproval, setCurrentApproval] = useState({
    approverPosition: "",
    approverName: "",
    state: {
      name: "",
    },
    associatedDocument: {
      name: "",
    },

    approvalComments: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    const requestApproval = async () => {
      const requestData = await getApprovalById({ approvalId: approvalId });
      const { company, userId } = JSON.parse(
        localStorage.getItem("payloadToken")
      );
      const { _id } = JSON.parse(localStorage.getItem("corporateUnit"));
      const obj = {
        consecutive: consecutive,
        company: company,
        corporateUnitId: _id,
        userId: userId,
      };
      const requestLawlayer = await findOneDataExternalRequest(obj);

      if (
        requestData.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestData.data.success &&
        requestLawlayer.status === CODES.COD_RESPONSE_HTTP_OK &&
        requestLawlayer.data.success
      ) {
        setCurrentApproval({
          ...requestData.data.responseMessage.data,
          attorneyInCharge: `${requestLawlayer.data.responseMessage.attorneyInCharge.firstName} ${requestLawlayer.data.responseMessage.attorneyInCharge.firstSurname}`,
        });
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    requestApproval();
  }, []);
  /**
   * Handles
   */

  //Download document
  const handleDownload = async (filenameInBucket, bucketName, fileName) => {
    const file = await getFileDocument(filenameInBucket, bucketName, false);
    const bf = Buffer.from(file.data.responseMessage.buffer.data);
    const blob = new Blob([bf]);
    const extention = fileName.split(".").pop().toLowerCase();
    let newName = "";
    if (extention === "docx") {
      newName = fileName;
    } else if (extention === "pdf") {
      newName = fileName;
    } else {
      newName = `${fileName}.docx`;
    }
    saveAs(blob, newName);
  };

  //Enviar
  const handleSend = async () => {
    setIsLoading(true);
    const companyId = localStorage.getItem("company");
    const corporateUnit = localStorage.getItem("corporateUnitId");
    const requestData = await addComment({
      consecutive: consecutive,
      approvalId: approvalId,
      newState: currentApproval.state._id,
      userName: currentApproval.approverName,
      userPosition: currentApproval.approverPosition,
      message: adjustmentMessage,
      associatedDocument: currentApproval.associatedDocument._id,
      isCommentOnly: false,
      sendEmail: false,
      isDiscussionComment: true,
      companyId,
      corporateUnit,
    });

    if (requestData.status === CODES.COD_RESPONSE_HTTP_OK) {
      navigate("confirmation");
    }
    setIsLoading(false);
  };

  return (
    <Container
      className="custom-container-scroll"
      style={{ paddingLeft: "4%" }}
    >
      {isLoading && <LoadingContent />}
      <Row className="label__description" style={{ marginTop: "5%" }}>
        Información de la aprobación
      </Row>
      <Row className="label__description" style={{ marginTop: "2%" }}>
        {" "}
        <span style={{ padding: "0" }}>
          <span className="label__subtitle">Aprobador: </span>
          {currentApproval.approverName}
        </span>
      </Row>
      <Row className="label__description">
        <span style={{ padding: "0" }}>
          <span className="label__subtitle">Usuario de servicios: </span>
          {currentApproval.attorneyInCharge}
        </span>
      </Row>
      <Row className="label__description">
        <span style={{ padding: "0" }}>
          <span className="label__subtitle">Último documento cargado: </span>
          <label
            onClick={() => {
              handleDownload(
                currentApproval.associatedDocument.filenameInBucket,
                currentApproval.associatedDocument.bucketName,
                currentApproval.associatedDocument.originalFilename
              );
            }}
            style={{
              color: "#17B4BC",
              cursor: "pointer",
            }}
          >
            {currentApproval.associatedDocument.originalFilename}
          </label>
        </span>
      </Row>
      <Row className="heading__primary-color" style={{ marginTop: "2%" }}>
        Solicitar ajustes
      </Row>
      <Row className="label__description" style={{ marginTop: "2%" }}>
        Por favor escriba un mensaje con los ajustes que solicita:
      </Row>
      <Row style={{ marginTop: "1%" }}>
        <Col md={6} style={{ paddingLeft: "0" }}>
          <Form.Group>
            <Form.Control
              as="textarea"
              style={{ minHeight: "120px", maxHeight: "200px" }}
              placeholder={"Está incorrecta la cláusula primera."}
              className="input-group-container__no-icon label text-fields"
              onChange={(e) => {
                setAdjustmentMessage(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row
        style={{ width: "50%", marginTop: "3%" }}
        className="justify-content-md-end"
      >
        <Col xs={2}>
          <Button
            className="custom-input__button__primary-color"
            onClick={() => {
              handleSend();
            }}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AdjustmentService;
