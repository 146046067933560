import { Col, Row } from "react-bootstrap";

const CardInformative = ({
  number,
  description,
  icon,
  size,
  color = "#00374f",
}) => {
  return (
    <Row style={{ alignItems: "center" }}>
      <Col 
        xs={"auto"}
        className={`informative-cards__${size}__icon-section`}
        style={{ backgroundColor: color }}
      >
        {icon}
      </Col>
      <Col xs={"auto"}>
        <p className="informative-cards__XSmall__title">
          {number}{" "}
          <span className="caption__disabled">{description}</span>
        </p>
      </Col>
    </Row>
  );
};

export default CardInformative;
