import { refreshToken } from "../services/Login/Login";
import { CODES } from "./codes";
import axios from "axios";

axios.interceptors.response.use(async (response) => {
  const originalRequest = response.config;
  if (
    response.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
    response.data.responseCode === CODES.COD_RESPONSE_ERROR_TOKEN_EXPIRED && // Use the status code your server returns when token has expired
    !originalRequest._retry
  ) {
    originalRequest._retry = true;
    const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));
    const obj = {
      email: payloadToken.email,
      refreshToken: localStorage.getItem("refreshToken"),
    };
    const refreshTokenService = await refreshToken(obj);
    if (refreshTokenService.status === CODES.COD_RESPONSE_HTTP_OK) {
      const newAccesToken = refreshTokenService.data.accessToken.jwtToken;
      localStorage.setItem("accessToken", newAccesToken);
      originalRequest.headers["Authorization"] = newAccesToken;
      return axios(originalRequest);
    } 
    /* else {
      window.location.replace("/login");
      localStorage.clear();
    } */
  } else if (
    response.status === CODES.COD_RESPONSE_HTTP_UNAUTHORIZED &&
    response.data.responseCode === CODES.COD_RESPONSE_ERROR_VALIDATE
  ) {
    window.location.replace("/");
    localStorage.clear();
  }
  return response;
});

const GetToken = () => {
  const token = localStorage.getItem("accessToken");
  if (!token) return "";
  return token;
};

export default GetToken;
