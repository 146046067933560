import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrum from "../../pages/parts/breadcrum";
import SectionTitle from "../../components/sectionTitle";
import CustomSearch from "../../components/Search/customSearch";
import { MDBDataTableV5 } from "mdbreact";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingContent from "../../components/LoadingContent";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchMinutas,
  getMinutasList,
  getStatus,
} from "../../reducers/minutasSlice";
import { Mixpanel } from "../../utils/mixPanel";

const SelectContractType = () => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const minutasStatus = useSelector(getStatus);
  const minutasList = useSelector(getMinutasList);
  const payloadToken = JSON.parse(localStorage.getItem("payloadToken"));

  //state
  const columns = [
    { field: "icon" },
    {
      label: [
        <label aria-hidden="true" key="1">
          Minuta
        </label>,
      ],
      field: "contractType",
    },
    {
      label: [
        <label aria-hidden="true" key="2">
          Descripción
        </label>,
      ],
      field: "description",
    },
    {
      label: [
        <label aria-hidden="true" key="3">
          Acciones
        </label>,
      ],
      field: "action",
    },
  ];
  const [dataTable, setDataTable] = useState({
    columns: columns,
    rows: [],
  });
  const [filteredDataTable, setFilteredDataTable] = useState({
    columns: columns,
    rows: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const buildRows = (data) => {
    if (!data?.length) {
      return [];
    }

    const formatedList = Array.isArray(data)
      ? data.map((contractType) => ({
          icon: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <BusinessCenterIcon fontSize="large" />
            </div>
          ),
          contractType: contractType.name,
          description:
            (contractType?.associatedCorporateUnits &&
              contractType?.associatedCorporateUnits[0]?.description) ||
            contractType?.publishData?.description,
          action: (
            <Container>
              <Row>
                <Col xs={"auto"}>
                  <Button
                    type="button"
                    variant="contained"
                    endIcon={<BusinessCenterIcon fontSize="large" />}
                    className="custom-input__button__primary-color"
                    onClick={() => {
                      navigate(`${contractType._id}`);
                      Mixpanel.track("Solicitante Usar minuta para contrato", {
                        email: payloadToken?.email,
                        companyName: payloadToken?.companyName,
                      });
                    }}
                  >
                    Usar minuta
                  </Button>
                </Col>
              </Row>
            </Container>
          ),
        }))
      : [
          {
            contractType: data,
          },
        ];

    return formatedList;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (minutasStatus === "fetch") {
          setIsLoading(true);
          const { company } = JSON.parse(localStorage.getItem("payloadToken"));
          const { _id } = JSON.parse(localStorage.getItem("corporateUnit"));
          let obj = {
            company: company,
            corporateUnit: _id,
            isPublish: "true",
          };
          await dispatch(fetchMinutas(obj)).then(() => {
            setIsLoading(false);
          });
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [minutasStatus, minutasList, dispatch]);

  useEffect(() => {
    const formatRows = buildRows(minutasList);
    setFilteredDataTable({
      columns: columns,
      rows: formatRows,
    });
    setDataTable({
      columns: columns,
      rows: formatRows,
    });
  }, [minutasList]);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum title="" />
      </Row>
      <Row style={{ alignContent: "center" }}>
        <Col xs={"auto"}>
          <SectionTitle title="Minutas" />
        </Col>
      </Row>
      <Row style={{ margin: "2% 0" }} className="label">
        Crea el contrato que necesites desde una minuta.
      </Row>
      <Row>
        <Col xs={4}>
          <CustomSearch
            placeholder="Buscar minuta"
            dataTableInfo={dataTable}
            setFilteredDataTable={setFilteredDataTable}
          />
        </Col>
      </Row>
      <Row>
        <MDBDataTableV5
          hover
          pagingTop
          searchBottom={false}
          noRecordsFoundLabel="La lista de minutas está vacía"
          entries={6}
          data={filteredDataTable}
          entriesLabel=""
          infoLabel={["Mostrando", "a", "de", "minutas"]}
          fullPagination
        />
      </Row>
    </Container>
  );
};

export default SelectContractType;
