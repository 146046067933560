import "./App.scss";
import "./styles/main.scss";
import AppRoutes from "./routes/index";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

function App() {
  return (
    <div>
      <NotificationContainer />
      <AppRoutes />
    </div>
  );
}

export default App;
