import { Col, Container, Row } from "react-bootstrap";

const AdjustmentConfirmation = () => {
  return (
    <Container fluid>
      <Row
        style={{
          marginTop: "19%",
        }}
        className="justify-content-md-center"
      >
        <Col md={"auto"}>
          <img
            className="confirmation__logo"
            src={
              require("../../../../../assets/svg/singleLogo.svg")
                .default
            }
          />
        </Col>
        <Col md={"auto"} className="confirmation__name">
          Legal AI
        </Col>
      </Row>
      <Row style={{ marginTop: "1%" }} className="justify-content-md-center">
        <hr
          style={{
            border: "1px solid #00374F",
            color: "#00374F",
            width: "70%",
          }}
        />
      </Row>
      <Row
        className="confirmation__comment justify-content-md-center"
        style={{ marginTop: "2%" }}
      >
        Su comentario ha sido enviado, muchas gracias por su tiempo.
      </Row>
      <Row className="body-content justify-content-md-center" style={{ marginTop: "2%" }}>
        Ya puede cerrar esta pestaña.
      </Row>
    </Container>
  );
};

export default AdjustmentConfirmation;
