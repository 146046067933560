import GetToken from "../../utils/getToken";
import axios from "axios";
import { env } from "../../env";

export const getContractsTypesByCompany = ({company, corporateUnit, isPublish}) => {
  return axios({
    method: "GET",
    url: "contractsTypes/getAll",
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      company,
      corporateUnit,
      isPublish
    },
    headers: {
      Authorization: GetToken(),
    },
  });
};

export function getContractTypeById({id, corporateUnit}) {
  return axios({
    method: "GET",
    url: `contractsTypes/getById/${id}`,
    baseURL: env.REACT_APP_API_URL,
    validateStatus: null,
    params: {
      corporateUnit,
    },
    headers: {
      Authorization: GetToken(),
    },
  });
};