export const getFillBlankSpaces = ({clause}) => {
    let count = 0;
    count =
      count +
      (clause.blankspaces.length > 0
        ? clause.blankspaces.filter((blank) => blank.value).length
        : 0);
    clause?.subclauses?.length > 0 &&
      clause.subclauses.map((sub) => {
        count =
          count +
          (sub.subclause?.blankspaces
            ? sub.subclause?.blankspaces.filter((blank) => blank.value).length
            : 0);
        sub?.subclause?.childs?.length > 0 &&
          sub.subclause.childs.map((child) => {
            count =
              count +
              (child?.blankspaces
                ? child?.blankspaces.filter((blank) => blank.value).length
                : 0);
          });
      });
    clause?.paragraphs?.length > 0 &&
      clause.paragraphs.map((para) => {
        count =
          count +
          (para.paragraph.blankspaces
            ? para.paragraph.blankspaces.filter((blank) => blank.value).length
            : 0);
      });
    return count;
  };
 export const getAllBlankSpaces = ({clause}) => {
    let count = 0;
    count = count + (clause.blankspaces ? clause.blankspaces.length : 0);
    clause?.subclauses?.length > 0 &&
      clause.subclauses.map((sub) => {
        count =
          count +
          (sub.subclause?.blankspaces ? sub.subclause?.blankspaces.length : 0);
        sub?.subclause?.childs?.length > 0 &&
          sub.subclause.childs.map((child) => {
            count =
              count + (child?.blankspaces ? child?.blankspaces.length : 0);
          });
      });
    clause?.paragraphs?.length > 0 &&
      clause.paragraphs.map((para) => {
        count =
          count +
          (para.paragraph.blankspaces ? para.paragraph.blankspaces.length : 0);
      });
    return count;
  };