import { configureStore } from "@reduxjs/toolkit";
import minutasSliceReducer from "../reducers/minutasSlice";
import requestSliceReducer from "../reducers/requestSlice";
import headersSliceReducer from "../reducers/headingsSlice";
import storageSliceReducer from "../reducers/storageSlice";
export default configureStore({
  reducer: {
    minutas: minutasSliceReducer,
    requests: requestSliceReducer,
    headers: headersSliceReducer,
    storage: storageSliceReducer,
  },
});
