import React, { useState } from "react";
import { Button } from "@mui/material";
import { Container, Row, Spinner, Form, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CODES } from "../../utils/codes";
import { REGEXP } from "../../utils/regexp";
import ModalInfo from "../../components/Modals/ModalInfo";
import { ForgotPasswordConfirmationService } from "../../services/Login/Login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isNewPasswordConfirmVisible, setIsNewPasswordConfirmVisible] =
    useState(false);

  // schema for form validation
  const schema = yup.object().shape({
    code: yup.string().required("*Este campo es obligatorio"),
    newPassword: yup
      .string()
      .required("*Este campo es obligatorio")
      .min(8, "Mínimo 8 caracteres")
      .test("upperCase", "*Al menos una letra mayúscula", function (value) {
        if (value) {
          const schema = yup.string().matches(REGEXP.ONE_UPPER_LETTER);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("lowerCase", "*Al menos una letra minúscula", function (value) {
        if (value) {
          const schema = yup.string().matches(REGEXP.ONE_LOWER_LETTER);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("number", "*Al menos un número", function (value) {
        if (value) {
          const schema = yup.string().matches(REGEXP.ONE_NUMBER);
          return schema.isValidSync(value);
        }
        return true;
      })
      .test("specialChar", "*Al menos un caracter especial", function (value) {
        if (value) {
          const schema = yup.string().matches(REGEXP.ONE_SPECIAL_CHAR);
          return schema.isValidSync(value);
        }
        return true;
      }),
    newPasswordConfirm: yup
      .string()
      .required("*Este campo es obligatorio")
      .oneOf([yup.ref("newPassword"), null], "*La contraseña no coincide"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleForgotPassword = async (data) => {
    try {
      setIsLoading(true);
      const obj = {
        email: params.email,
        password: data.newPassword,
        confirmationCode: data.code,
      };

      const forgotPasswordConfirmationServiceResponse =
        await ForgotPasswordConfirmationService(obj);

      if (
        forgotPasswordConfirmationServiceResponse.status ===
        CODES.COD_RESPONSE_HTTP_OK
      ) {
        if (
          forgotPasswordConfirmationServiceResponse.data.success &&
          forgotPasswordConfirmationServiceResponse.data.responseCode ===
            CODES.COD_RESPONSE_SUCCESS
        ) {
          setResponseData(forgotPasswordConfirmationServiceResponse);
          setIsOpenModal(true);
          setIsLoading(false);
        }
      } else if (
        forgotPasswordConfirmationServiceResponse.status ===
        CODES.COD_RESPONSE_HTTP_ERROR
      ) {
        setResponseData(forgotPasswordConfirmationServiceResponse);
        setIsOpenModal(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false);
    navigate("/");
  };

  return (
    <div className="login-page-form">
      <Container fluid>
        <Row
          className="container-forgot-password"
          style={{
            margin: "0% 20%",
            padding: "5% 0%",
          }}
        >
          <Container>
            <Row className="align-center">
              <p className="display-x-large__primary-color">
                Recuperar contraseña
              </p>
            </Row>
            <Row className="align-center">
              <p className="display-large__primary-color-hover">
                Usuario solicitante
              </p>
            </Row>
            <Row className="form-container__forgotPassword">
              <Form onSubmit={handleSubmit(handleForgotPassword)}>
                <Form.Group className="mb-5">
                  <InputGroup
                    bsPrefix={
                      errors.code
                        ? "input-group-container__error"
                        : "input-group-container"
                    }
                  >
                    <InputGroup.Text bsPrefix="container-icon">
                      <KeyOutlinedIcon fontSize="large" />
                    </InputGroup.Text>
                    <Form.Control
                      {...register("code")}
                      bsPrefix="custom-input"
                      placeholder="Código"
                    />
                  </InputGroup>
                  <div
                    className="caption custom-input__error"
                    style={{ border: "1px sollid red" }}
                  >
                    {errors.code?.message}
                  </div>
                </Form.Group>
                <Form.Group className="mb-5">
                  <InputGroup
                    bsPrefix={
                      errors.newPassword
                        ? "input-group-container__error"
                        : "input-group-container"
                    }
                  >
                    <InputGroup.Text bsPrefix="container-icon">
                      <LockOutlinedIcon fontSize="large" />
                    </InputGroup.Text>
                    <Form.Control
                      {...register("newPassword")}
                      type={!isNewPasswordVisible ? "password" : "text"}
                      bsPrefix="custom-input"
                      placeholder="Nueva contraseña"
                    />
                    <FontAwesomeIcon
                      size="lg"
                      icon={!isNewPasswordVisible ? faEye : faEyeSlash}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() =>
                        setIsNewPasswordVisible(!isNewPasswordVisible)
                      }
                    />
                  </InputGroup>
                  <div className="caption custom-input__error">
                    {errors.newPassword?.message}
                  </div>
                </Form.Group>

                <Form.Group className="mb-5">
                  <InputGroup
                    bsPrefix={
                      errors.newPasswordConfirm
                        ? "input-group-container__error"
                        : "input-group-container"
                    }
                  >
                    <InputGroup.Text bsPrefix="container-icon">
                      <LockOutlinedIcon fontSize="large" />
                    </InputGroup.Text>
                    <Form.Control
                      {...register("newPasswordConfirm")}
                      type={!isNewPasswordConfirmVisible ? "password" : "text"}
                      bsPrefix="custom-input"
                      placeholder="Confirmar nueva contraseña"
                    />
                    <FontAwesomeIcon
                      size="lg"
                      icon={!isNewPasswordConfirmVisible ? faEye : faEyeSlash}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() =>
                        setIsNewPasswordConfirmVisible(
                          !isNewPasswordConfirmVisible
                        )
                      }
                    />
                  </InputGroup>
                  <div className="caption custom-input__error">
                    {errors.newPasswordConfirm?.message}
                  </div>
                  <Form.Label className="caption__helper-text">
                    Usa 8 o más caracteres con una combinación de letras en
                    mayúscula y minúscula, números y símbolos
                  </Form.Label>
                </Form.Group>
                <Form.Group style={{ textAlign: "center", padding: "0% 10%" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="custom-input__button__primary-color"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        className="spinner__white-background"
                      />
                    ) : (
                      "Guardar"
                    )}
                  </Button>
                </Form.Group>
              </Form>
            </Row>
          </Container>
        </Row>
      </Container>
      <ModalInfo
        title={"Actualización de contraseña"}
        responseData={responseData}
        open={isOpenModal}
        onClose={handleClose}
      />
    </div>
  );
};
export default ForgotPassword;
