import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { CircularProgress } from "@mui/material";
import { getSignedURLFronEmail } from "../services/Approvals/ApprovalServices";
import { CODES } from "../utils/codes";

export const DownloadFile = () => {
  const [searchParams] = useSearchParams();
  const bucketName = searchParams.get("bucketName");
  const filenameInBucket = searchParams.get("filenameInBucket");

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function downloadFile() {
      if (bucketName && filenameInBucket) {
        setIsLoading(true);
        try {
          const service = await getSignedURLFronEmail(
            filenameInBucket,
            bucketName
          );
          if (service.status === CODES.COD_RESPONSE_HTTP_OK) {
            setIsLoading(false);
            window.open(service.data, "_blank");
          }
        } catch (error) {}
      }
    }
    downloadFile();
  }, [searchParams]);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <p className="heading__primary-color">
          Archivo descargado correctamente
        </p>
      )}
    </div>
  );
};
