import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CODES } from "../utils/codes";
import { ShowDataExternalRequestCarvajal } from "../services/CarvServices/CarvajalServices";

export const requestSlice = createSlice({
    name: "requests",
    initialState: {
        requestList: [],
        requestStatus: "fetch",
        error: ""
    },

    reducers:{
        setStatusRequest(state, action) { 
            state.requestStatus = action.payload.status;
        },
        setUpdateOneRequest(state, action) {
            const idRequestUpdate = action.payload.id;
            const requestUpdate = action.payload.requestData;
            const updateList = state.requestList.map((request) => (request?._id === idRequestUpdate ? requestUpdate : request));
            state.requestList = updateList;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchRequest.pending, (state, __) => {
            state.requestStatus = "loading";
        })
        .addCase(fetchRequest.rejected, (state, __) => {
            state.requestStatus = "error";
        })
        .addCase(fetchRequest.fulfilled, (state, action) => {
            if (action.payload.status === CODES.COD_RESPONSE_HTTP_OK && action.payload.data.success) {
                state.requestStatus = "success";
                state.requestList = action.payload.data.responseMessage
            } else {
                state.requestStatus = "error";
            }
        })
    }
})

export const getRequestStatus = (state) => state.requests.requestStatus;

export const getRequestList = (state) => state.requests.requestList;

export const { setStatusRequest } = requestSlice.actions;

export const { setUpdateOneRequest } = requestSlice.actions;

export const fetchRequest = createAsyncThunk("requests/fetchRequest", async (data,{rejectWithValue}) => {
    try {
        const response = await ShowDataExternalRequestCarvajal(data);
        return {
            status: response.status,
            data: response.data
        }
    } catch (error) {
        rejectWithValue(error.response);
    }
});

export default requestSlice.reducer;