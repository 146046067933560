import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const useAuth = ({ redirectPath = "/", children, requiredRole }) => {
  const accessToken = localStorage.getItem("accessToken");
  const existAccessToken = !!accessToken;
  const infoToken = localStorage.getItem("infoToken");
  const existInfoToken = !!infoToken;

  if (!existAccessToken || !existInfoToken) {
    return <Navigate to={redirectPath} replace />;
  }

  const userInfo = JSON.parse(localStorage.getItem("payloadToken"))
  const role = userInfo["custom:role"];
  if (role === requiredRole) {
    return children || <Outlet />;
  }
};

export default useAuth;
