export const APPROVAL_STATE = {
  APPROVED: {
    _id: "629fb9275f7d5320e0942b25",
    colorId: null,
    description:
      " - El aprobador haya aprobado el documento mediante el botón de ‘Aprobar’",
    name: "Aprobada",
  },
  DESIST: {
    _id: "629fb9275f7d5320e0942b26",
    colorId: null,
    description:
      " - Aparecerá cuando se haya dado click en 'Desistir aprobación'",
    name: "Desistida",
  },
  RETURNED: {
    _id: "629fb9275f7d5320e0942b24",
    colorId: null,
    description:
      " - El aprobador devolvió la solicitud mediante el botón de ‘Solicitar ajustes’. - El aprobador devolvió la solicitud de aprobación mediante el botón de ‘Cargar un documento’",
    name: "Devuelta",
  },
  PENDING: {
    _id: "629fb9275f7d5320e0942b23",
    colorId: null,
    description:
      " - Se ha solicitado aprobación pero aún no han dado respuesta. - Se hizo click en “Recordar aprobación”  pero aún no han dado respuesta. - Se solicitó nuevamente la aprobación de un documento pero aún no han dado respuesta",
    name: "Pendiente",
  },
  INACTIVE: {
    _id: "626a9acf56c1dd7a1b145cb8",
    colorId: null,
    description:
      " - No hay almacenamiento displone en el plan de la compañía.",
    name: "Pendiente",
  },
};
