import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { env } from "../env";

const ProtectedMaintenance = ({ redirectPath = "/maintenance", children }) => {
    const isMaintenance = env.REACT_APP_IN_MAINTENANCE === 'true';

    if(!isMaintenance) return children || <Outlet />;

    return <Navigate to={redirectPath} replace />;
};

export function ModuleMaintenance () {
    const isMaintenance = env.REACT_APP_IN_MAINTENANCE === 'true';

    if (!isMaintenance) return <Navigate to={"/"} replace />;

    return (<div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <img
        src={require("../assets/svg/maintenance.svg").default}
        alt="logo-maintenance"
      />
    </div>)
}

export default ProtectedMaintenance;