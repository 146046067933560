import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LoadingContent from "../../../../../components/LoadingContent";
import ModalInfo from "../../../../../components/Modals/ModalInfo";
import {
  addComment,
  getApprovalById,
} from "../../../../../services/Approvals/ApprovalServices";
import { APPROVAL_STATE } from "../../../../../utils/approvalsState";
import { CODES } from "../../../../../utils/codes";

const ApprovalConfirmed = () => {
  /**
   * Use Navigate
   */

  const navigate = useNavigate();

  /**
   * Use Params
   */
  const { consecutive, approvalId } = useParams();

  /**
   * Use State
   */

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [responseData, setResponseData] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  /**
   * Use Effect
   */

  useEffect(() => {
    const sendApprovedComment = async () => {
      const requestData1 = await getApprovalById({ approvalId: approvalId });
        
      const companyId = localStorage.getItem("company");
      const corporateUnit = localStorage.getItem("corporateUnitId");

      if (
        (requestData1.status ===
          CODES.COD_RESPONSE_HTTP_OK && requestData1.data.success)
      ) {
        const currentApproval = requestData1.data.responseMessage.data;
        const requestData2 = await addComment({
          consecutive: consecutive,
          approvalId: approvalId,
          newState: APPROVAL_STATE.APPROVED._id,
          userName: currentApproval.approverName,
          userPosition: currentApproval.approverPosition,
          message: `El aprobador ${currentApproval.approverName} aprobó el documento.`,
          associatedDocument: currentApproval.associatedDocument._id,
          isCommentOnly: true,
          sendEmail: false,
          isDiscussionComment: true,
          companyId,
          corporateUnit
        });

        if (
          (requestData2.status ===
            CODES.COD_RESPONSE_HTTP_OK && requestData2.data.success)
        ) {
          setResponseData({
            status: CODES.COD_RESPONSE_HTTP_OK,
            data: {
              responseMessage: "La aprobación fue remitida con éxito",
            },
          });
          setOpenConfirmationModal(true);
        }
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    sendApprovedComment();
  }, []);

  return (
    <Container>
      {isLoading && <LoadingContent />}
      <ModalInfo
        title={"¡Aprobado!"}
        responseData={responseData}
        open={openConfirmationModal}
        onClose={() => {
          navigate("/");
        }}
      />
    </Container>
  );
};

export default ApprovalConfirmed;
