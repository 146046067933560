export const QUESTION_REQUEST_EXTERNAL = {
    ADDRESS: "Domicilio de la otra parte (ciudad)",
    ADDRESS_AGENT_LEGAL: "Domicilio del representante legal (ciudad y país)",
    COUNTRY: "País de domicilio",
    DOCUMENT_NUMBER: "Número de identificación de la otra parte",
    DOCUMENT_NUMBER_AGENT_LEGAL: "Tipo y número de documento de identidad del representante legal",
    EMAIL: "Correo electrónico de notificaciones",
    NAME: "Nombre o razón social de la otra parte",
    NAME_AGENT_LEGAL: "Nombre completo del representante legal",
    TYPE_PERSON: "¿La otra parte es una persona jurídica?",
};