import React, { useState, useEffect } from "react";
import { GetRequestStatusInfo } from "../../services/ExternalUsers/ExternalRequest";
import { Row, Col, Container } from "react-bootstrap";
import LoadingContent from "../LoadingContent";
import Breadcrum from "../../pages/parts/breadcrum";
import SectionTitle from "../sectionTitle";
import { MDBBadge } from "mdbreact";
import { handleStyleStatusRequest } from "../../utils/statusExtenalRequest";

const InfoStatus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [statusInfo, setStatusInfo] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const service = await GetRequestStatusInfo();
        if (service) {
          if (service.status) {
            const { responseMessage } = service.data;
            console.log(responseMessage);
            const StatusRows = responseMessage.map((item) => {
              return {
                status: (
                  <MDBBadge
                    color=""
                    pill
                    style={{
                      backgroundColor: handleStyleStatusRequest(item.colorId),
                      fontSize: "1.5rem",
                      borderRadius: "4px",
                      padding: "0.4rem",
                    }}
                  >
                    <span className="label__description">{item.name}</span>
                  </MDBBadge>
                ),
                description: item.description,
              };
            });
            setStatusInfo(StatusRows);
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle
          title={"Información acerca de los estados de una solicitud"}
        />
      </Row>
      <Row style={{ marginTop: "30px" }}>
        {statusInfo?.length > 0 &&
          statusInfo.map((item) => (
            <Row key={item.status}>
              <Col xs={12} md={3}>
                <p className="label__description">{item.status}</p>
              </Col>
              <br />
              <br />
              <Col xs={12} md={9}>
                <p className="label__description">{item.description}</p>
              </Col>
            </Row>
          ))}
      </Row>
    </Container>
  );
};

export default InfoStatus;
