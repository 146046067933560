import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CODES } from "../utils/codes";
import { getContractsTypesByCompany } from "../services/ContractType/ContractTypeServices";

export const minutasSlice = createSlice({
  name: "minutas",
  initialState: {
    minutasList: "",
    minutasStatus: "fetch",
    error: "",
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMinutas.pending, (state, action) => {
        state.minutasStatus = "loading";
      })
      .addCase(fetchMinutas.fulfilled, (state, action) => {
        state.minutasStatus = "succeeded";
        // Add any fetched posts to the array
        state.minutasList = action.payload;
      })
      .addCase(fetchMinutas.rejected, (state, action) => {
        state.minutasStatus = "error";
        state.error = action.error.message;
      });
  },
});

export const getMinutasList = (state) => state.minutas.minutasList;

export const getStatus = (state) => state.minutas.minutasStatus;

export const fetchMinutas = createAsyncThunk(
  "minutas/fetchMinutas",
  async ({ company, corporateUnit, isPublish }) => {
    const response = await getContractsTypesByCompany({
      company,
      corporateUnit,
      isPublish,
    });
    if (
      response.status === CODES.COD_RESPONSE_HTTP_OK &&
      response.data.success
    ) {
      return response.data.responseMessage;
    }
  }
);

export default minutasSlice.reducer;
