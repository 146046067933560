import { CODES } from "./codes";

export const statusList = [
  {
    id: "621fdd55e1b7dfa85f10bf4c",
    Status: "Revisión solicitante",
    description:
      "La solicitud fue enviada al solicitante para que haga una revisión y actualización de la solicitud.",
  },
  {
    id: "621fdd36e1b7dfa85f10bf4a",
    Status: "Revisión abogado",
    description:
      "La solicitud fue enviada al abogado para que haga una revisión y actualización de la solicitud.",
  },
  {
    id: "621fdd87e1b7dfa85f10bf4e",
    Status: "Solucionada",
    description:
      "La solicitud fue solucionada gracias a que se culminó con el proceso entregando el documento solicitado en su versión final.",
  },
  {
    id: "621fdd97e1b7dfa85f10bf50s",
    Status: "Cancelada",
    description:
      "La solicitud fue cancelada debido a que el usuario solicitante solicitó su cancelación.",
  },
];

export const subTypesList = [
  "Confidencialidad",
  "Prestación de servicios",
  "Arrendamiento",
  "Promesa compraventa",
  "Compraventa",
  "Suministro",
  "Obra",
  "Otro",
  "Otrosí",
];

export const carvajalTypeList = [
  "Contrato/Otrosí",
  "Respuesta al cliente/proveedor - NDA - Formatos",
  "Consulta Jurídica",
  "Formulario Solicitud de formularios RFP/RFI - Documentos licitaciones - Formatos proveedores",
];

export const handleStyleStatusRequest = (colorId) => {
  if (colorId === CODES.COD_LIGHT_ORANGE) {
    return "#FFD7B1";
  } else if (colorId === CODES.COD_LIGHT_GREEN) {
    return "#CCF4E3";
  } else if (colorId === CODES.COD_LIGHT_RED) {
    return "#F9DADE";
  } else if (colorId === CODES.COD_LIGHT_PURPLE) {
    return "#E6E9EF";
  } else if (colorId === CODES.COD_LIGHT_BLUE) {
    return "#CBECEE";
  } else {
    return "#F3DEBC";
  }
};

export const STATUS_REQUESTS = {
  REVIEW_APPLICANT: {
    name: "Revisión solicitante",
    id: "621fdd55e1b7dfa85f10bf4c",
  },
  REVIEW_ATTORNEY: {
    name: "Revisión abogado",
    id: "621fdd36e1b7dfa85f10bf4a",
  },
  SOLVED: {
    name: "Solucionada",
    id: "621fdd87e1b7dfa85f10bf4e",
  },
  CANCELLED: {
    name: "Cancelada",
    id: "621fdd97e1b7dfa85f10bf50",
  },
};
