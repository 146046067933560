import React, { useState, useEffect } from "react";
import { Container, Row, Form, Col, Spinner } from "react-bootstrap";
import SectionTitle from "../components/sectionTitle";
import Breadcrum from "../pages/parts/breadcrum";
import { useForm, useFieldArray } from "react-hook-form";
import { Button } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { CODES } from "../utils/codes";
import { useNavigate } from "react-router-dom";
import LoadingContent from "../components/LoadingContent";
import * as yup from "yup";
import ModalInfo from "../components/Modals/ModalInfo";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { SendInfoToSupport } from "../services/ExternalUsers/ExternalRequest";
import {
  DownLoadRecentDocument,
  responseDataFileSize,
  responseDataInvalidFormat,
  responseDataNoFile,
  validationsSupportDocuments,
} from "../utils/downloadDocuments";

const ExternalHelp = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState("second");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [isOpenModalNoFile, setIsOpenModalNoFile] = useState(false);
  const [isOpenModalInvalidFormat, setIsOpenModalInvalidFormat] =
    useState(false);
  const [documentIndex, setDocumentIndex] = useState(0);
  const [isOpenModalError, setIsOpenModalError] = useState(false);

  const schema = yup.object().shape({
    subject: yup
      .string()
      .required("*Este campo es obligatorio")
      .max(120, "Máximo 120 caracteres permitidos"),
    functionality: yup
      .string()
      .required("*Este campo es obligatorio")
      .max(250, "Máximo 250 caracteres permitidos"),
    description: yup
      .string()
      .required("*Este campo es obligatorio")
      .max(1000, "Máximo 1000 caracteres permitidos"),
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    name: "supportDocuments",
    control,
  });

  useEffect(() => {
    const inputElement = document.getElementById(
      `supportDocuments.${documentIndex}`
    );

    if (inputElement) {
      inputElement.dispatchEvent(new MouseEvent("click"));
    }
  }, [fields]);

  const handleInfoToAdminSupport = async (data) => {
    try {
      setIsLoading(true);

      const validateDocuments = validationsSupportDocuments(
        data.supportDocuments
      );

      if (validateDocuments?.isNoFile) {
        setIsOpenModalNoFile(true);
        throw new Error("exit");
      }
      if (validateDocuments?.isInvalidFormat) {
        setIsOpenModalInvalidFormat(true);
        throw new Error("exit");
      }
      if (validateDocuments?.isInvalidSize) {
        setIsOpenModalInfo(true);
        throw new Error("exit");
      }

      const listDocuments = data.supportDocuments.map(
        (document) => document[0]
      );

      let userInfo = JSON.parse(localStorage.payloadToken);
      const obj = {
        subject: data.subject,
        message: data.description,
        userEmail: userInfo.email,
        firstName: userInfo.firstName || userInfo.companyName,
        ...(userInfo.firstSurname && { lastName: userInfo.firstSurname }),

        supportDocuments: listDocuments,
        additionalInfo: {
          functionality: data.functionality,
          company: userInfo.companyName,
          userType: userInfo.loginType,
          role: userInfo.subRole,
        },
      };

      const service = await SendInfoToSupport(obj);
      setResponseData(service);
      if (service?.status === CODES.COD_RESPONSE_HTTP_CREATED) {
        setIsOpenModal(true);
      } else {
        setIsOpenModalError(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteOtherDocument = (index) => {
    remove(index);
  };

  const handleUploadOtherDocument = (index) => {
    setDocumentIndex(index);
    append({});
  };

  const handleDownloadRecentDocument = (index) => {
    const fileDownload = getValues(`supportDocuments.${index}`);
    DownLoadRecentDocument(fileDownload);
  };
  const handleReset = () => {
    reset();
  };

  return (
    <Container fluid>
      {isLoading && <LoadingContent />}
      <Row>
        <Breadcrum />
      </Row>
      <Row xs={"auto"}>
        <SectionTitle title={`Solicitar ayuda a soporte`} />
      </Row>
      <Row xs={"auto"}>
        <p
          className="home-init-cards-row__title-row"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          Por favor diligencia la siguiente información:
        </p>
      </Row>
      <Row>
        <Form onSubmit={handleSubmit(handleInfoToAdminSupport)}>
          <Row>
            <Col md={5} xs={12}>
              <Form.Group className="mb-3">
                <Form.Label className="caption">Asunto: </Form.Label>
                <Form.Control
                  {...register("subject")}
                  type="text"
                  placeholder="Solicitud de..."
                  size="lg"
                  rows={3}
                  maxLength="120"
                />
                <p
                  className="caption custom-input__error mt-1"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.subject?.message}
                </p>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={5} xs={12}>
              <Form.Group className="mb-3">
                <Form.Label className="caption">Funcionalidad: </Form.Label>
                <Form.Control
                  {...register("functionality")}
                  type="text"
                  placeholder="Revisar, crear, carpetas..."
                  size="lg"
                  rows={3}
                  maxLength="250"
                />
                <p
                  className="caption custom-input__error mt-1"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.functionality?.message}
                </p>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={5} xs={12}>
              <Form.Group className="mb-3">
                <Form.Label className="caption">Descripción: </Form.Label>
                <Form.Control
                  {...register("description")}
                  type="text"
                  as="textarea"
                  placeholder="Necesito ayuda en..."
                  size="lg"
                  rows={5}
                  maxLength="1000"
                />
                <p
                  className="caption custom-input__error"
                  style={{ border: "1px sollid red" }}
                >
                  {errors.description?.message}
                </p>
              </Form.Group>
            </Col>
          </Row>
          <>
            {fields.map((field, index) => {
              const file =
                watch(`supportDocuments.${index}`) &&
                watch(`supportDocuments.${index}`)[0];
              return (
                <Row key={field.id}>
                  <Col md={5} xs={12}>
                    <Form.Control
                      key={field.id}
                      id={`supportDocuments.${index}`}
                      className="input-upload"
                      {...register(`supportDocuments.${index}`)}
                      type="file"
                    />
                    <Row
                      htmlFor={`supportDocuments.${index}`}
                      style={{
                        color: "#00374F",
                        height: "max-content",
                        margin: "1rem 0rem",
                        boxShadow: "4px 4px 25px 3px rgba(0, 0, 0, 0.05)",
                        borderRadius: "8px",
                      }}
                    >
                      <Col style={{ margin: "1.5rem" }} md={7}>
                        <Row className="label__title">{`Documento ${
                          index + 1
                        }`}</Row>
                        <Row>{file ? `${file.name}` : ""}</Row>
                      </Col>
                      <Col style={{ margin: "1.5rem" }} md={3}>
                        <Row>
                          <Col md={6}>
                            <Form.Label
                              onClick={() =>
                                handleDownloadRecentDocument(index)
                              }
                              className="btn_blue_background"
                              style={{
                                backgroundColor: "#00374F",
                                marginRight: "1rem",
                                padding: "7px",
                                cursor: "pointer",
                              }}
                            >
                              <DownloadIcon fontSize="large" />
                            </Form.Label>
                          </Col>
                          <Col md={6}>
                            <Form.Label
                              className="btn_blue_background"
                              color="#676879"
                              style={{
                                backgroundColor: "#00374F",
                                marginRight: "1rem",
                                padding: "7px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDeleteOtherDocument(index)}
                            >
                              <DeleteIcon fontSize="large" />
                            </Form.Label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
            <br />
            {fields.length < 3 && (
              <Row xs={"auto"}>
                <Button
                  type="button"
                  variant="contained"
                  startIcon={<AddIcon fontSize="large" />}
                  className="custom-input__button__secondary-color"
                  onClick={() => handleUploadOtherDocument(fields.length)}
                >
                  Cargar Documentos
                </Button>
              </Row>
            )}
          </>
          <Row className="mt-3">
            <Col xs={5}>
              <Row className="justify-content-center">
                {" "}
                <Col xs={6} className="text-center">
                  <button
                    type="button"
                    className="btn_white_background"
                    style={{ padding: "12px 24px" }}
                    onClick={handleReset}
                  >
                    Restablecer
                  </button>
                </Col>
                <Col xs={6} className="text-center">
                  <button
                    type="submit"
                    className="btn_blue_background"
                    style={{ padding: "12px 24px" }}
                  >
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        className="spinner__blue_background"
                      />
                    ) : (
                      "Enviar"
                    )}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Row>
      <ModalInfo
        title={"Soporte Legal AI"}
        responseData={responseData}
        open={isOpenModal}
        onClose={() => navigate("/external/home")}
      />
      <ModalInfo
        title="Cajas de archivos vacías"
        open={isOpenModalNoFile}
        onClose={() => setIsOpenModalNoFile(false)}
        responseData={responseDataNoFile}
      />
      <ModalInfo
        title="Formato de archivos inválido"
        open={isOpenModalInvalidFormat}
        onClose={() => setIsOpenModalInvalidFormat(false)}
        responseData={responseDataInvalidFormat}
      />
      <ModalInfo
        title="Peso de archivos inválido"
        open={isOpenModalInfo}
        onClose={() => setIsOpenModalInfo(false)}
        responseData={responseDataFileSize}
      />
      <ModalInfo
        title={"Ha ocurrido un error"}
        responseData={responseData}
        open={isOpenModalError}
        onClose={() => setIsOpenModalError(false)}
      />
    </Container>
  );
};

export default ExternalHelp;
