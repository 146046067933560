import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
const PopoverActions = ({
  type = "default",
  description,
  icon,
  classNameButton = "custom-input__button__default-color",
  actions,
  containerRef,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const popover = (
    <Popover
      style={{ margin: "0" }}
      className={`custom-popover__${type}`}
      id={props.id}
    >
      <Popover.Body className="custom-popover body-content__center">
        <div
          style={{
            width: "max-content",
            position: "absolute",
            right: "0",
            top: "0",
          }}
        >
          <IconButton aria-label="close" onClick={() => setShow(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Row className="custom-popover__body">{description}</Row>
        <Row style={{ justifyContent: "center" }}>
          {actions?.map(
            (item, index) =>
              item.show && (
                <Col key={uuidv4()} xs={"auto"}>
                  <Button
                    className="custom-input__button__withOutBackground"
                    size="large"
                    onClick={item.handleAction}
                  >
                    {item.text}
                  </Button>
                </Col>
              )
          )}
        </Row>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      show={show}
      placement="auto"
      overlay={popover}
      rootClose
      trigger="click"
      container={containerRef?.current}
    >
      <IconButton
        aria-label="icon"
        className={classNameButton}
        sx={{ borderRadius: "4px" }}
        onClick={() => setShow((show) => !show)}
      >
        {icon}
        {props?.textIcon || ""}
      </IconButton>
    </OverlayTrigger>
  );
};

export default PopoverActions;
