import { getFileDocument } from "../services/Approvals/ApprovalServices";
import { saveAs } from "file-saver";

export const DownLoadRecentDocument = (getFile) => {
  const file = getFile?.[0];
  if (file) {
    const blob = new Blob([file]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
  }
};

export const responseDataFileSize = {
  data: {
    responseMessage:
      "No ha sido posible enviar el formulario porque uno o más de los archivos excede el peso de 20MB.",
  },
  status: 1000,
};

export const responseDataNoFile = {
  data: {
    responseMessage:
      "No ha sido posible enviar el formulario porque una o más de la cajas no cuenta con archivo cargado. Elimínala y carga una nueva.",
  },
  status: 1000,
};

export const responseDataInvalidFormat = {
  data: {
    responseMessage:
      "No ha sido posible enviar el formulario porque uno o más de de los archivos no se encuentra en formato Word, PDF, png o jpg.",
  },
  status: 1000,
};

const FORMATS_VALIDATIONS = [
  "application/pdf",
  "application/msword",
  "image/jpeg",
  "image/png",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const validationsSupportDocuments = (documents) => {
  let response = {
    isNoFile: false,
    isInvalidFormat: false,
    isInvalidSize: false,
  };
  if (!documents?.length) return response;

  for (const document of documents) {
    if (Object.keys(document).length === 0) {
      return {
        ...response,
        isNoFile: true,
      };
    }
    if (!FORMATS_VALIDATIONS.includes(document[0]?.type)) {
      return {
        ...response,
        isInvalidFormat: true,
      };
    }
    if (document[0]?.size > 20000000) {
      return {
        ...response,
        isInvalidSize: true,
      };
    }
  }
};

export const downloadDocumentByFilenameAndBucketName = async ({
  filenameInBucket,
  bucketName,
  isWord = false,
}) => {
  try {
    const file = await getFileDocument(filenameInBucket, bucketName, isWord);
    const bf = file.data.responseMessage.buffer.data;
    const blob = new Blob([new Uint8Array(bf).buffer]);
    let fileName = file.data.responseMessage.filename;
    const contentType =
      fileName.split(".").length === 1 && file.data.responseMessage.contentType;
    if (
      contentType &&
      contentType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      fileName = fileName + ".docx";
    } else if (contentType && contentType === "application/pdf") {
      fileName = fileName + ".pdf";
    }
    saveAs(blob, fileName);
  } catch (error) {
    throw new Error(error);
  }
};
