import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { CODES } from "../../utils/codes";
import SubModalGeneral from "./SubModalGeneral";
const ModalInfo = ({ title, responseData, onClose, open }) => {
  const { data, status } = responseData;
  const handleClose = () => {
    onClose();
  };
  let titleColor = "";
  if (
    status === CODES.COD_RESPONSE_HTTP_OK ||
    status === CODES.COD_RESPONSE_HTTP_CREATED
  ) {
    titleColor = "success";
  } else if (status === CODES.COD_RESPONSE_HTTP_ERROR) {
    titleColor = "error";
  } else {
    titleColor = "warning";
  }

  const classNameTitle = `heading__${titleColor}-color`;

  return (
    <div>
      <Dialog
        // maxWidth="xl"
        fullWidth
        onClose={handleClose}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <SubModalGeneral onClose={handleClose}>
          <label className={classNameTitle}>
            {(titleColor === "success" || titleColor === "warning") && (
              <InfoOutlinedIcon fontSize="large" />
            )}

            {titleColor === "error" && (
              <WarningAmberOutlinedIcon fontSize="large" />
            )}

            {" " + title}
          </label>
        </SubModalGeneral>
        <DialogContent className="caption" dividers>
          {data?.responseMessage?.message || data?.responseMessage || data?.message}
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              variant="contained"
              className="custom-input__button__primary-color"
              onClick={handleClose}
            >
              Aceptar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalInfo;
