export const REGEXP = {
  ONLY_LETTERS: /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
  PHONE_NUMBER: /^(\d{1,4}|\(\d{2,3}\)|\d{2,4})?\d{3,4}\d{3,4}$/,
  ONLY_NUMBERS: /^\d+$/,
  LETTERS_COMMAS: /^[,A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
  ONE_UPPER_LETTER: /^(?=.*?[A-Z])/,
  ONE_LOWER_LETTER: /[a-z]/,
  ONE_NUMBER: /\d/,
  ONE_SPECIAL_CHAR: /[#?!@$%^&*-]/,
  PERCENTAGE: /^[1-9]\d?$|^100$/,
  EMAIL: /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,3}$/
};
